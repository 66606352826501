@import "_vars.less";
@import "_mixins.less";
//@import url('@{fa-font-path}/css/all.css');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    background: #fff;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
}

img {
    display: block;
    max-width: 100%;
}

/* BOOTSTRAP FIXES */

.btn {
    border-radius: @default-border-radius;
}

.btn-primary {
    background-color: @dark-blue;
    border-color: @dark-blue;

    &:hover {
        background-color: @dark-orange;
        border-color: @dark-orange;
    }
}

.btn-active {
    background: @dark-blue;
    color: #fff;

    &:hover, &:focus {
        color: #fff;
        box-shadow: none;
        outline: none;
    }
}

.btn-mobile-menu {
    display: none;
}

.no-padding {
    padding: 0;
}

.container {
    width: 90%;
    margin: 0 auto;
    padding: 0 15px;
    max-width: 90%;
}

//.row {
//    margin: 0 -15px;
//    display: flex;
//
//    &::after {
//        clear: both;
//        content: "";
//    }
//}

header {
    background-color: #001092;
}

.header {
    &__top-bar {
        background: @blue-link;
        padding: 12px 0;
        margin-bottom: 24px;
    }

    &__languages {
        display: flex;
        list-style-type: none;
        gap: 10px;
        margin-bottom: 0;

        &__item {
            color: #fff;
            text-transform: uppercase;

            &--disabled {
                color: #777;
            }
        }
    }

    &__account-links {

        &__items {
            display: flex;
            list-style-type: none;
            margin-bottom: 0;

            &__item {
                margin: 0 10px;

                &__link {
                    color: #fff;
                    text-decoration: none;

                    &:hover {
                        color: #fff;
                    }
                }

                &__auth {
                    align-items: center;
                    display: flex;
                    color: #fff;

                    & strong {
                        margin-left: 10px;
                    }
                }
            }
        }
    }

    &__middle-lane {
        justify-content: space-between;
        margin-bottom: 36px;
        margin-top: 36px;
        gap: 48px;
    }

    &__logo {
        flex: 0 0 25.5%;
        display: flex;
        align-items: center;
    }

    &__search {
        flex: 1 1 28%;
        display: flex;
        align-items: center;
        justify-content: space-around;

        form {
            position: relative;
            display: block;
            width: 100%;
        }

        &__input {
            .reset-button-style();
            line-height: 42px;
            padding: 0 52px 0 20px;
            border-radius: @default-border-radius;
            width: 100%;
            background: #fff;
        }

        &__button {
            .reset-button-style();
            position: absolute;
            background: none;
            padding: 12px 8px;
            right: 8px;
            cursor: pointer;

            .fa-search {
                font-size: 16px;;
            }
        }
    }

    &__contacts {
        flex: 1 1 15%;

        &__items {
            list-style: none;
            flex-flow: row;
            display: flex;
            justify-content: space-around;
            margin-bottom: 0;

            &__item {
                color: #fff;
                font-size: 16px;

                &__link {
                    color: #fff;
                    text-decoration: none;
                    font-size: 16px;

                    &:hover {
                        color: #fff;
                    }
                }
            }
        }
    }

    &__schedule {
        flex: 0 0 12%;

        &__heading {
            color: #fff;
            text-transform: uppercase;
            font-weight: bold;
            font-size: 16px;
        }

        &__times {
            font-size: 16px;
            color: #fff;
        }
    }

    &__cart {
        display: flex;
        padding-right: 30px;
        align-items: center;
        justify-content: center;

        &__button {
            position: relative;
            text-decoration: none;
            color: #fff;
            .reset-button-style();
        }

        &__count {
            position: absolute;
            top: -10px;
            right: -20px;
            width: 17px;
            text-align: center;
            color: #121212;
            line-height: 16px;
            font-size: 11px;
            height: 17px;
            background: #fff;
            border-radius: 50%;
        }

        .fa-shopping-cart {
            font-size: 28px;
            color: @orange;
        }
    }

    &__callback {
        margin-left: auto;
        flex: 0 0 11%;
        display: flex;
        align-items: center;
        justify-content: center;

        &__button {
            .reset-button-style();
            display: flex;
            color: #fff;
            background: @dark-orange;
            padding: 11px 18px;
            border-radius: @default-border-radius;
        }

        & .fa-phone {
            margin-right: 16px;
            transform: rotate(140deg);
        }
    }

    &__menu {
        flex: 1 1 100%;
        justify-content: space-between;
        display: flex;
        flex-flow: row;
        margin: 5px 0 0;

        &__catalog {
            flex: 1 1 15%;
            margin-right: 96px;
            position: relative;

            &__button {
                background: @orange;
                display: block;
                text-align: center;
                line-height: 48px;
                border-radius: @default-border-radius;
                font-size: 20px;
                text-transform: uppercase;
                text-decoration: none;
                color: #fff;
                transition: .3s all;
                margin-bottom: 24px;

                &:hover {
                    color: #fff;
                    text-decoration: none;
                    background: @dark-orange;
                }

                .fa-angle-down {
                    font-size: 20px;
                    position: relative;
                    top: 1px;
                    left: 10px;
                }
            }

            &__block {
                display: none;
                position: absolute;
                background: @dark-menu;

                &.opened {
                    display: block;
                    z-index: 3;
                    min-width: 150%;
                    padding: 5px 15px 5px 15px;
                    top: 56px;

                    & img {
                        position: absolute;
                        z-index: 1;
                        height: 50px;
                        width: 50px;
                        left: 0;
                        top: 0;
                    }

                    & ul.cat-level-1 {
                        margin-bottom: 0;

                        & > li {
                            list-style-type: none;
                            border-bottom: 1px solid #aec0ce;
                            padding: 15px 10px 15px 60px;
                            position: relative;

                            &:last-child {
                                border-bottom: none;
                            }

                            &:hover > ul.cat-level-2 {
                                display: block;
                            }

                            & > ul.cat-level-2 {
                                position: absolute;
                                display: none;
                                left: 100%;
                                width: 100%;
                                top: -5px;
                                padding-top: 5px;
                                background: @dark-menu;
                                box-shadow: -4px 4px 4px rgba(0, 0, 0, 0.2);

                                & > li {
                                    list-style-type: none;
                                    border-bottom: 1px solid #aec0ce;
                                    padding: 15px 10px 15px 15px;
                                    position: relative;

                                    &:last-child {
                                        border-bottom: none;
                                    }

                                    &:hover > ul.cat-level-3 {
                                        display: block;
                                    }

                                    & > ul.cat-level-3 {
                                        position: absolute;
                                        display: none;
                                        left: 100%;
                                        top: -5px;
                                        padding-top: 5px;
                                        width: 100%;
                                        background: @dark-menu;
                                        box-shadow: -4px 4px 4px rgba(0, 0, 0, 0.2);

                                        & > li {
                                            list-style-type: none;
                                            border-bottom: 1px solid #aec0ce;
                                            padding: 15px 10px 15px 15px;
                                            position: relative;

                                            &:last-child {
                                                border-bottom: none;
                                            }

                                            &:hover > ul.cat-level-4 {
                                                display: block;
                                            }

                                            & > ul.cat-level-4 {
                                                position: absolute;
                                                display: none;
                                                left: 100%;
                                                top: -5px;
                                                padding-top: 5px;
                                                width: 100%;
                                                background: @dark-menu;
                                                box-shadow: -4px 4px 4px rgba(0, 0, 0, 0.2);

                                                & > li {
                                                    list-style-type: none;
                                                    border-bottom: 1px solid #aec0ce;
                                                    padding: 15px 10px 15px 15px;
                                                    position: relative;

                                                    &:last-child {
                                                        border-bottom: none;
                                                    }

                                                    & > a {
                                                        text-decoration: none;
                                                        font-size: 16px;
                                                        color: #fff;

                                                        &::after {
                                                            content: "\f30b";
                                                            font-family: 'Font Awesome 5 Free';
                                                            font-weight: 900;
                                                            position: absolute;
                                                            right: 10px;
                                                        }

                                                        &:hover {
                                                            color: @dark-blue;
                                                        }
                                                    }
                                                }
                                            }

                                            & > a {
                                                text-decoration: none;
                                                font-size: 16px;
                                                color: #fff;

                                                &::after {
                                                    content: "\f30b";
                                                    font-family: 'Font Awesome 5 Free';
                                                    font-weight: 900;
                                                    position: absolute;
                                                    right: 10px;
                                                }

                                                &:hover {
                                                    color: @dark-blue;
                                                }
                                            }
                                        }
                                    }

                                    & > a {
                                        text-decoration: none;
                                        font-size: 16px;
                                        color: #fff;

                                        &::after {
                                            content: "\f30b";
                                            font-family: 'Font Awesome 5 Free';
                                            font-weight: 900;
                                            position: absolute;
                                            right: 10px;
                                        }

                                        &:hover {
                                            color: @dark-blue;
                                        }
                                    }
                                }
                            }

                            & > a {
                                text-decoration: none;
                                font-size: 16px;
                                color: #fff;

                                &::after {
                                    content: "\f30b";
                                    font-family: 'Font Awesome 5 Free';
                                    font-weight: 900;
                                    position: absolute;
                                    right: 10px;
                                }

                                &:hover {
                                    color: @dark-blue;
                                }
                            }
                        }
                    }
                }
            }
        }

        &__items {
            list-style: none;
            display: flex;
            flex: 1 1 85%;
            justify-content: space-between;
            margin-bottom: 0;

            &__item__link {
                color: #fff;
                text-transform: uppercase;
                text-decoration: none;
                font-size: 18px;
                line-height: 48px;
                text-align: center;
                position: relative;
                display: block;

                &:after {
                    content: "";
                    height: 1px;
                    width: 0%;
                    left: 0;
                    display: block;
                    transition: .3s width;
                    background: #fff;
                    position: absolute;
                    bottom: 5px;
                }

                &:hover {
                    text-decoration: none;
                    color: #fff;
                }

                &:hover:after {
                    width: 100%;
                }
            }
        }
    }
}

.block__title {

    border-bottom: 1px solid #aec0ce;
    margin-bottom: 20px;

    &__inner {
        text-align: center;
        font-weight: bold;
        font-size: 28px;
        width: auto;
        display: inline-block;
        position: relative;

        &::after {
            content: "";
            position: absolute;
            width: 100%;
            border-bottom: 3px solid #fa9908;
            bottom: 0;
            left: 0;
        }
    }

}

.home {
    &__tabs {
        margin: 40px 0;

        &__items {
            display: flex;
            justify-content: center;
            list-style-type: none;
            border-bottom: 1px solid #aec0ce;
            margin-bottom: 15px;

            &__item {
                margin-right: 50px;

                &__link {
                    color: #292d3a;
                    font-size: 28px;
                    font-weight: bold;
                    text-decoration: none;
                    border-bottom: 3px solid transparent;
                    display: block;
                    padding: 0 15px 5px;

                    .active &, &:hover {
                        border-color: @orange;
                        text-decoration: none;
                        color: inherit;
                    }
                }
            }
        }
    }

    &__map {
        iframe {
            border: none;
            width: 100%;
            height: 500px;
        }
    }

    &__features {
        margin: 80px 0 50px;
    }

    &__partners {
        text-align: center;
        margin-bottom: 20px;

        &__block {
            &__image {
                display: flex;
                justify-content: center;
            }
        }
    }
}

.feature {

    &__icon {
        height: 60px;
        display: flex;
        justify-content: center;

        img {
            height: 55px;
        }
    }

    &__heading {
        font-size: 24px;
        text-transform: uppercase;
        color: #121212;
        font-weight: 900;
        margin-top: 20px;
        min-height: 75px;
        line-height: 28px;
    }

    &__text {
        font-size: 16px;
    }

}

.product__card {
    flex: 0 0 20%;
    text-align: center;
    display: flex;
    flex-flow: column;
    justify-content: center;
    background: #d9e9f6;
    padding: 25px 20px;

    &__image {
        display: flex;
        justify-content: center;
        margin-bottom: 15px;
    }

    &__code {
        color: #444;
        margin-bottom: 10px;
    }

    &__title {
        margin-bottom: 10px;

        &__link {
            text-decoration: none;
            color: #121212;
            font-weight: bold;
            transition: .1s all;

            &:hover {
                color: @dark-blue;
            }
        }
    }

    &__price {
        margin-bottom: 10px;

        span {
            font-weight: bold;
        }
    }

    &__buttons {
        &__add-to-cart {
            cursor: pointer;
            .reset-button-style();
            line-height: 40px;
            padding: 0 25px;
            border-radius: @default-border-radius;
            color: @dark-blue;
            background: @orange;
            transition: .3s all;
            font-size: 14px;

            &:hover {
                background: @dark-blue;
                color: #fff;
            }
        }
    }
}

main {
    min-height: calc(100vh - 240px - 260px);
    position: relative;

    &.hover::before {
        content: "";
        background: rgba(0, 0, 0, 0.4);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
    }

    &.catalog__page {
        background: url('/img/catalog-bg.png') left top repeat-y,
        url('/img/catalog-bg-r.png') 100% top repeat-y #fff;
    }

    /* Catalog */

    .category {
        &__name {
            text-align: center;
        }
    }

    .glide {
        &__slide {
            a {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
            }
            img {
                display:block;
                width: 100%;
            }
        }
    }

}

.pagination {
    flex-wrap: wrap;
}

.page {
    &__heading {
        padding-top: 0;
        margin-bottom: 12px;
    }

    &__product {

        &__price {
            font-size: 36px;
            font-weight: bold;

            &__heading {
                font-size: 28px;
            }
        }

        &__listing ul {
            list-style-type: none;

            li {
                span {
                    font-weight: bold;
                }

                .max-qnty {
                    font-style: normal;
                }
            }

        }

        &__btns {
            display: flex;
            align-items: center;

            button.btn-plus, button.btn-minus {
                .reset-button-style();
                background: @dark-blue;
                height: 36px;
                width: 36px;
                color: #fff;
                font-size: 18px;
                line-height: 36px;
            }

            input[name="quantity"] {
                .reset-button-style();
                text-align: center;
                border-top: 1px solid @dark-blue;
                border-bottom: 1px solid @dark-blue;
                margin: 0 -3px;
                height: 36px;
                border-radius: 0;
                line-height: 36px;
                font-size: 18px;
                width: 50px;
            }

            .btn-primary {
                margin-left: 30px;
            }
        }

    }
}

.breadcrumbs {
    display: flex;
    margin: 36px 0 36px;

    & li {
        list-style-type: none;
        color: #aec0ce;
        font-weight: bold;

        &::after {
            content: "";
            background: #3242cf;
            display: inline-block;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            margin: 0 10px;
            position: relative;
            top: -3px;
            left: -2px;
        }

        &:last-child::after {
            display: none;
        }

        & a {
            color: #3242cf;
            font-weight: bold;
        }
    }
}

.char {
    &__box {
        margin: 5px 0;

        &__items {
            overflow: scroll;
            max-height: 20em;
        }
    }

    &__box + &__box {
        border-top: 1px solid #eee;
    }

    &__name {
        font-size: 20px;
        margin-bottom: 16px;
        margin-top: 16px;
        margin-left: 6px;
    }

    &__line {
        padding: 3px 7px;

        & > input {
            margin-right: 4px;
        }
    }
}

.filter {
    &__heading {
        text-align: center;
        font-size: 28px;
        margin: 10px 0;
    }

    &__box {

        background: #fff;
        padding: 0;
        margin-bottom: 16px;

        &__categories {
            list-style: none;
            padding: 10px;

            & li {
                margin-bottom: 10px;

                &:last-child {
                    margin-bottom: 0;
                }

                & a {
                    color: #121212;
                }
            }
        }
    }

    &__categories {
        & a {
            color: #121212;
            font-size: 18px;
            padding: 10px;
            display: block;
        }
    }
}

.show-more {
    cursor: pointer;

    &:hover {
        color: #001092;
    }
}

.modal {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 2;
    background: rgba(0, 0, 0, 0.3);

    &.show {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__callback {
        width: 500px;
        padding: 20px;
        background: #fff;
        border-radius: 10px;
        position: relative;

        &__heading {
            text-align: center;
            font-size: 18px;
            margin-bottom: 20px;
        }

        & input {
            margin-bottom: 15px;
        }

        & .fa-times-circle {
            position: absolute;
            right: 3px;
            top: 3px;
            font-size: 24px;
            color: #121212;
            cursor: pointer;
        }
    }

    &__response {
        text-align: center;
        font-size: 24px;
    }

    &--message {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        z-index: 2;
        background: rgba(0, 0, 0, 0.3);

        &.show {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &__heading {
            margin-bottom: 15px;
        }

        &__block {
            width: 500px;
            padding: 20px;
            background: #fff;
            border-radius: 10px;
            position: relative;

            & .fa-times-circle {
                position: absolute;
                right: 3px;
                top: 3px;
                font-size: 24px;
                color: #121212;
                cursor: pointer;
            }
        }

    }
}

.display-btns {
    padding-right: 15px;

    .row {
        justify-content: end;
    }
}

/* REGISTER */


.group--diller, .group--fop {
    display: none;
}

.group--show {
    display: block;
}

//.group--hidden {
//    display: none;
//}

#register {
    .required {
        &::after {
            content: '*';
            position: absolute;
            color: #f00;
            right: 5px;
            top: 1px;
        }
    }
}

.requiredText {
    color: #f00;
}

.product {
    &__block {
        background: #fff;
        border: 1px solid #ededed;
        text-align: center;
        color: #121212;
        position: relative;
        padding: 15px 30px;
        margin-right: -1px;
        margin-bottom: -1px;

        & > .row {
            justify-content: center;
        }
    }

    &__stock {
        position: absolute;
        top: 15px;
        color: #fff;
        background: #2a9055;
        padding: 4px 12px;
        right: 15px;
        font-size: 12px;
        border-radius: @default-border-radius;

        &.out-stock {
            background: @dark-blue;
        }

        & .fa-check {
            margin-right: 5px;
        }
    }

    &__code {
        flex: 1 1 100%;
        font-size: 12px;
        text-align: left;
        margin-bottom: 20px;
        color: #777;
    }

    &__name {
        flex: 0 0 100%;
        max-height: 23px;
        overflow: hidden;
        text-align: left;

        a {
            color: @dark-blue;
            font-size: 16px;
        }
    }

    &__image {
        text-align: center;
        margin-bottom: 15px;
        padding-top: 35px;

        & img {
            display: inline-block;
        }
    }

    &__price {
        font-size: 24px;
        margin-right: 15px;
        flex: 1 1 50%;
        text-align: left;
    }

    &__btn {
        background: @dark-orange;
        border: 1px solid @dark-orange;
        width: 100%;
    }

    &__details {
        display: none;

        &__link {
            display: none;
            cursor: pointer;
        }
    }
}


.row.show_table {
    & > div {
        flex: 0 0 100% !important;
        max-width: 100% !important;
    }

    .product {
        &__block {
            //display: flex;
            //justify-content: space-between;
            //align-items: center;
            padding: 5px;
            margin-bottom: 10px;

            & > .row {
                padding: 0 15px;
                justify-content: space-between;
                align-items: center;
            }
        }

        &__name {
            flex: 0 0 40%;
        }

        &__image {
            margin-left: 5px;

            img {
                height: 75px;
                max-width: 75px;
                max-height: 75px;
            }
        }

        &__btn {
            margin-right: 5px;
        }

        &__details {
            display: flex;
            height: 0;
            overflow: hidden;
            align-items: flex-start !important;

            &__link {
                display: block;
            }
        }
    }
}

.characteristics-heading {
    font-weight: bold;
    font-size: 24px;
    margin: 20px 0 30px;
}

.news {
    &__title {
        margin-bottom: 15px;

        & a {
            font-size: 18px;
            font-weight: bold;
            color: @dark-blue;
        }
    }

    &__short {
        text-align: justify;
        font-size: 14px;
    }
}

footer {
    background: @dark-blue;
    padding: 55px 0 24px;
    color: @footer-color;
    max-height: 260px;
}

.footer {

    &__row {
        justify-content: space-between;
    }

    &__menu {
        flex: 0 0 25%;

        &__heading {
            font-size: 18px;
            font-weight: bold;
            text-transform: uppercase;
            margin-bottom: 12px;
        }

        &__list__items {
            list-style: none;

            &__item {
                margin-bottom: 5px;

                &__link {
                    text-decoration: none;
                    color: @footer-color;

                    &:hover {
                        text-decoration: underline;
                        color: #fff;
                    }
                }
            }
        }
    }

    &__subscribe_n_info {
        flex: 0 0 25%;
        margin-left: auto;

        &__heading {
            margin-bottom: 12px;
        }

        &__form {
            position: relative;

            &__input {
                .reset-button-style();
                border-radius: @default-border-radius;
                line-height: 40px;
                padding: 0 35px 0 20px;
                border-radius: 15px;
                width: 100%;
                background: #fff;
                margin-bottom: 15px;
            }

            &__button {
                .reset-button-style();
                position: absolute;
                color: #121212;
                font-size: 24px;
                right: 15px;
                line-height: 40px;
            }
        }

        &__info {
            &__heading {
                font-size: 18px;
                margin-bottom: 12px;
            }
        }
    }

    &__copyright {
        text-align: center;
        width: 100%;
        margin: 25px 0 0;
        color: @footer-color;
    }

}

.page-link {
    color: #001092;
}

.page-item.active .page-link {
    background: #001092;
    border-color: #001092;
}


@import "responsive.less";
