.left-mobile-menu {
    display: none;
}

.filter__heading {
    display: none;
}


@media screen and (max-width: 1910px) {
    .header {
        &__callback {
            flex: auto;
        }
    }
}

@media screen and (max-width: 1440px) {
    .header {
        &__schedule {
            display: none;
        }

        &__contacts {
            flex: 1 1 10%;
        }

        &__callback {
            flex: 1 1 10%;
        }
    }
}

@media screen and (max-width: 1200px) {
    header {
        position: relative;
        z-index: 2;
    }

    main.hover::before {
        z-index: 1;
    }

    .header {
        &__contacts {
            display: none;
        }

        &__search {
            flex: 1 0 40%;
            padding: 0 40px;
        }

        &__logo {
            flex: 0 0 23%;
        }

        &__middle-lane {
            padding: 10px 0;
        }

        &__menu {
            &__catalog {
                margin-right: 30px;
            }
        }
    }

    .left-mobile-menu {
        display: none;
        position: absolute;

        &.active {
            background: #001092;
            position: absolute;
            z-index: 1;
            left: 0;
            width: 50%;
            display: flex;
            flex-flow: column;
            height: 100vh;

            .row--menu {
                margin-left: 0;
                margin-right: 0;
                flex-flow: column;
            }

            .row--top {
                margin-right: 15px;
                margin-left: 15px;
            }

            .header__menu {
                flex-flow: column;

                &__catalog {
                    margin: 0 15px 5px;

                    &__block.opened {
                        min-width: 100%;
                    }
                }

                &__items {
                    flex-flow: column;
                }
            }
        }
    }

    .product__card {
        flex: 0 0 25%;
    }
}

@media screen and (max-width: 1150px) {
    .btn-mobile-menu {
        display: inline-block;
        position: absolute;
        color: @orange;
        font-size: 28px;
        top: 0;
        left: 0;
        padding: 0 10px;
        z-index: 11;

        &:hover, &:active, &:focus {
            color: @orange;
            border: 1px solid transparent;
            outline: none;
            box-shadow: none;
        }
    }

    .header {
        &__middle-lane {
            margin-top: 0;
            margin-bottom: 16px;
        }

        &__callback {
            &__button {
                .fa-phone {
                    margin-right: 0;
                }

                span {
                    display: none;
                }
            }
        }

        &__menu {
            &__catalog {
                margin-right: 0;

                &__block {
                    &.opened {
                        width: 100%;
                        min-width: 100%;
                    }
                }
            }

            &__items {
                display: none;

                &__item {
                    &__link {
                        font-size: 24px;
                    }
                }

                &.opened {
                    position: fixed;
                    left: 0;
                    top: 0;
                    background: rgba(0, 0, 0, 0.8);
                    display: flex;
                    flex-direction: column;
                    text-align: center;
                    width: 100%;
                    height: 100vh;
                    z-index: 10;
                    justify-content: space-around;
                }
            }
        }
    }
}

@media screen and (max-width: 992px) {
    .product__card {
        flex: 0 0 calc(100% / 3);
    }
}


@media screen and (max-width: 940px) {
    .header {
        & > .container {
            max-width: 100%;
            width: 100%;
        }

        &__languages {
            padding-left: 5vw;
        }

        &__search {
            flex: 1 0 60%;
        }

        &__cart {
            flex: 1 0 5%;
            padding-right: 0;
        }

        &__callback {
            flex: 1 0 4%;

            &__button {
                span {
                    display: none;
                }

                .fa-phone {
                    transform: rotate(130deg);
                    font-size: 22px;
                }
            }
        }
    }

    .breadcrumbs {
        flex-wrap: wrap;
    }
}

@media screen and (max-width: 768px) {
    .header {
        &__logo {
            flex: 0 0 100%;
            justify-content: center;
        }
    }

    .filter {
        &__heading {
            display: block;
            cursor: pointer;
            background: @orange;
            border-radius: 5px;
            color: #fff;
            padding: 10px 15px;
            margin: -10px -15px 20px;
        }

        &__box {
            display: none;

            &.opened {
                display: block;
            }
        }
    }

    .product__card {
        flex: 0 0 calc(100% / 2);
    }

    .product__code, .product__stock {
        position: initial;
    }

    .product__image {
        padding-top: 15px;
    }

    .footer {
        max-height: 100%;

        &__menu {
            flex: 0 0 50%;
        }

        &__subscribe_n_info {
            margin-left: 0;
            flex: 1 1 100%;
        }
    }
}

@media screen and (max-width: 755px) {
    .header {
        &__search {
            flex: 0 0 auto;
        }
    }
}

@media screen and (max-width: 650px) {
    .header {
        &__search {
            flex: 0 0 100%;
            padding: 0 10px;
        }
    }
}

@media screen and  (max-width: 400px) {
    .product__code, .product__stock {
        font-size: 10px;
    }

    .product__block {
        padding: 8px;
    }
}
